import { render, staticRenderFns } from "./Assignment.vue?vue&type=template&id=6816ae04&scoped=true&"
import script from "./Assignment.vue?vue&type=script&lang=js&"
export * from "./Assignment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6816ae04",
  null
  
)

export default component.exports