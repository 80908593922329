<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-text>
          <b-row>
            <b-col
              cols="12"
            >
              <h6 class="mb-1">
                Duyuru Oluştur
              </h6>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Duyuru Tipi">
                <v-select
                  v-model="getFormData.id_com_hr_notification_types"
                  :options="types"
                  label="title"
                  :reduce="type => type.id"
                  placeholder="Duyuru Tipi"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
      <welcome v-if="getFormData.id_com_hr_notification_types === '1'" />
      <baby v-if="getFormData.id_com_hr_notification_types === '2'" />
      <leaving v-if="getFormData.id_com_hr_notification_types === '3'" />
      <promote v-if="getFormData.id_com_hr_notification_types === '4'" />
      <death v-if="getFormData.id_com_hr_notification_types === '5'" />
      <seniority v-if="getFormData.id_com_hr_notification_types === '6'" />
      <assignment v-if="getFormData.id_com_hr_notification_types === '7'" />
      <b-button
        v-if="!submitStatus"
        variant="primary"
        @click="submitForm"
      >
        Kaydet
      </b-button>
      <b-button
        v-else
        variant="primary"
        disabled
      >
        <b-spinner small />
        Lütfen Bekleyiniz...
      </b-button>
    </b-col>
    <b-col
      v-if="getFormData.id_com_hr_notification_types"
      cols="12"
      class="mt-3"
    >
      <b-card>
        <b-card-title>Önizleme</b-card-title>
        <WelcomePreview v-if="getFormData.id_com_hr_notification_types === '1'" />
        <BabyPreview v-if="getFormData.id_com_hr_notification_types === '2'" />
        <LeavingPreview v-if="getFormData.id_com_hr_notification_types === '3'" />
        <PromotePreview v-if="getFormData.id_com_hr_notification_types === '4'" />
        <DeathPreview v-if="getFormData.id_com_hr_notification_types === '5'" />
        <SeniorityPreview v-if="getFormData.id_com_hr_notification_types === '6'" />
        <AssignmentPreview v-if="getFormData.id_com_hr_notification_types === '7'" />
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardTitle, BCardText, BRow, BCol, BButton, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Welcome from '@/views/Hr/Notifications/Forms/Welcome.vue'
import Baby from '@/views/Hr/Notifications/Forms/Baby.vue'
import Leaving from '@/views/Hr/Notifications/Forms/Leaving.vue'
import Promote from '@/views/Hr/Notifications/Forms/Promote.vue'
import Death from '@/views/Hr/Notifications/Forms/Death.vue'
import Seniority from '@/views/Hr/Notifications/Forms/Seniority.vue'
import Assignment from '@/views/Hr/Notifications/Forms/Assignment.vue'
import WelcomePreview from '@/views/Hr/Notifications/Preview/Welcome.vue'
import BabyPreview from '@/views/Hr/Notifications/Preview/Baby.vue'
import LeavingPreview from '@/views/Hr/Notifications/Preview/Leaving.vue'
import PromotePreview from '@/views/Hr/Notifications/Preview/Promote.vue'
import DeathPreview from '@/views/Hr/Notifications/Preview/Death.vue'
import SeniorityPreview from '@/views/Hr/Notifications/Preview/Seniority.vue'
import AssignmentPreview from '@/views/Hr/Notifications/Preview/Assignment.vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    vSelect,
    Welcome,
    Baby,
    Leaving,
    Promote,
    Death,
    Seniority,
    Assignment,
    WelcomePreview,
    BabyPreview,
    LeavingPreview,
    PromotePreview,
    DeathPreview,
    SeniorityPreview,
    AssignmentPreview,
  },
  data() {
    return {
      submitStatus: false,
    }
  },
  computed: {
    types() {
      return this.$store.getters['hrNotificationTypes/getHr_notification_types']
    },
    saveStatus() {
      return this.$store.getters['hrNotifications/getHr_notificationSaveStatus']
    },
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.resetFormData()
    this.getTypes()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getTypes() {
      this.$store.dispatch('hrNotificationTypes/hr_notification_typesList', {
        select: [
          'com_hr_notification_types.id AS id',
          'com_hr_notification_types.title AS title',
        ],
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('hrNotifications/hr_notificationSave', this.getFormData)
    },
    resetFormData() {
      this.$store.dispatch('hrNotifications/hr_notificationReset')
    },
  },
}
</script>
